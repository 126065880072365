<template>
    <v-dialog light v-model="chkpointDialog" persistent max-width="750px">
        <v-card>
            <v-card-title class="headline px-2">
                <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h5 pl-0">
                        {{
                            formData.id
                            ? "Editar Checkpoint"
                            : "Novo Checkpoint"
                        }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-fab-transition> -->
                    <v-btn @click="toogleChkpointAdm" class="mt-2" color="red" fab icon dark x-small absolute top right>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <!-- </v-fab-transition> -->
                </v-app-bar>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="frmChkpointAdm">
                        <v-row>
                            <v-col cols="12" sm="6" md="7">
                                <v-text-field v-model="formData.name" label="Nome do Pacote"
                                    :rules="$store.state.formRules.required"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="5">
                                <v-text-field-money v-model="formData.price" v-bind:label="'Preço / Valor'"
                                    v-bind:properties="{
                                        rules: $store.state.formRules.required,
                                        prefix:
                                            formData.price && formData.price.length ? 'R$' : '',
                                        readonly: false,
                                        disabled: false,
                                        // formData.id && !parseInt(formData.can_delete),
                                        outlined: false,
                                        clearable: true,
                                        placeholder: ' ',
                                    }" v-bind:options="{
    locale: 'pt-BR',
    length: 11,
    precision: 2,
    empty: null,
}" />
                                <!-- <v-text-field
                    v-model="formData.price"
                    label="Preço"
                    :rules="$store.state.formRules.required"
                  ></v-text-field> -->
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="toogleChkpointAdm">
                    Fechar
                </v-btn>
                <v-btn color="green" text @click="saveCheckpoint"> Salvar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
const chkpointAdmFrm = {
    name: "",
    price: ""
}
export default {
    methods: {
        saveCheckpoint() {
            let url = this.formData.id ? "api/checkpoint/licences/update" : "api/checkpoint/licences/create"
            let obj = {
                url: url,
                query: this.formData,
                method: "post",
            };

            this.$store.dispatch("request/doRequest", obj).then((response) => {
                if (!response) {
                    return;
                }
                let opts = {
                    message: "Checkpoint salvo com sucesso!",
                    type: "success",
                };
                this.$store.commit("showAlert", opts);
                this.toogleChkpointAdm()
                this.getCheckpoints()
            });
        },
        setEscClose() {
            let _this = this;
            document.onkeydown = function (evt) {
                evt = evt || window.event;
                var isEscape = false;
                if ("key" in evt) {
                    isEscape = (evt.key === "Escape" || evt.key === "Esc");
                } else {
                    isEscape = (evt.keyCode === 27);
                }
                if (isEscape) {
                    _this.toogleChkpointAdm()
                    document.onkeydown = null;
                }
            };
        },
    },
    watch: {
        chkpointDialog(v) {
            if (!v) {
                document.onkeydown = null;
                this.formData = { ...chkpointAdmFrm }
                this.$refs.frmChkpointAdm.resetValidation();
            } else {
                this.setEscClose()
                if (this.editChkpointData) {
                    this.formData = { ...this.editChkpointData }
                }
            }
        }
    },
    data() {
        return {
            formData: { ...chkpointAdmFrm }
        }
    },
    props: {
        toogleChkpointAdm: Function,
        chkpointDialog: Boolean,
        editChkpointData: Object,
        getCheckpoints: Function
    },
}
</script>

<style></style>