var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 opacityTable mx-15",attrs:{"headers":_vm.headers,"items":_vm.checkpoints,"items-per-page":5},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm._f("formatPrice")(item.price))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1 mr-3",attrs:{"x-small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.editChkpoint(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" edit ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Editar Checkpoint")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1",attrs:{"x-small":"","color":"red","dark":""},on:{"click":function($event){return _vm.confirmDelChkpoint(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" mdi-delete-forever ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Excluir Checkpoint")])])]}}])}),_c('CheckpointForm',{attrs:{"getCheckpoints":_vm.getCheckpoints,"editChkpointData":_vm.editChkpointData,"chkpointDialog":_vm.chkpointDialog,"toogleChkpointAdm":_vm.toogleChkpointAdm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }