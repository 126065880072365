<template>
  <v-row justify="center">
    <v-dialog light v-model="addPacksDialog" persistent max-width="750px">
      <v-card>
        <v-card-title class="headline px-2">
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="text-h5 pl-0">
              {{
                formData.id
                ? "Editar Pacote adicional"
                : "Novo Pacote adicional"
              }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-fab-transition> -->
            <v-btn @click="toogleRegisterAddPacksDilog" class="mt-2" color="red" fab icon dark x-small absolute top right>
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- </v-fab-transition> -->
          </v-app-bar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="addPacksForm" v-model="valid">
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <!-- :disabled="formData.id && !parseInt(formData.can_delete)" -->
                  <v-text-field v-model="formData.name" label="Nome do Pacote adicional"
                    :rules="$store.state.formRules.required"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select :disabled="formData.id && !parseInt(formData.can_delete)"
                    :rules="$store.state.formRules.required" v-model="formData.id_service" :items="services"
                    label="Escolha um aplicativo" item-text="name" item-value="id"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-radio-group row v-model="formData.urn_basic">
                    <v-radio label="Pacote premium" :value="0"></v-radio>
                    <v-radio label="Pacote básico" :value="1"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" md="6" class="mt-0 pr-5">
                  <v-menu v-model="start_commercialization_menu" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="290px">
                    <!-- :disabled="
                          formData.id && !parseInt(formData.can_delete)
                        " -->
                    <template v-slot:activator="{ on }">
                      <v-text-field clearable v-model="start_commercialization_formated"
                        label="Data de início da comercilização" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="formData.start_commercialization"
                      @input="start_commercialization_menu = false"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="6" class="mt-0 pr-5">
                  <v-menu v-model="end_commercialization_menu" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field clearable v-model="end_commercialization_formated"
                        label="Data final da comercialização" readonly hint="Preencher em caso de encerramento do pacote."
                        persistent-hint v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="formData.end_commercialization"
                      @input="end_commercialization_menu = false"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field-money v-model="formData.price" v-bind:label="'Preço / Valor'" v-bind:properties="{
                    rules: $store.state.formRules.required,
                    prefix:
                      formData.price && formData.price.length ? 'R$' : '',
                    readonly: false,
                    disabled: formData.id && !parseInt(formData.can_delete),
                    outlined: false,
                    clearable: true,
                    placeholder: ' ',
                  }" v-bind:options="{
  locale: 'pt-BR',
  length: 11,
  precision: 2,
  empty: null,
}" />
                  <!-- <v-text-field
                    v-model="formData.price"
                    label="Preço"
                    :rules="$store.state.formRules.required"
                  ></v-text-field> -->
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field :disabled="formData.id && !parseInt(formData.can_delete)" v-model="formData.urn_prefix"
                    label="URN" :rules="$store.state.formRules.required"></v-text-field>
                </v-col>
              </v-row>
              <v-row :class="[
                'mt-0 pt-3',
                cancelNewPrice ? 'blue-grey lighten-5 borderRadius' : '',
              ]">
                <v-col v-if="formData.id" cols="12" sm="12" class="pl-0 pb-0 pt-0">
                  <v-btn :ripple="false" class="ml-2" @click="setNewPrice" plain
                    :color="cancelNewPrice ? 'error' : 'primary'" elevation="0" rounded small>
                    <v-icon small class="pr-1">{{
                      cancelNewPrice ? "remove" : "add"
                    }}</v-icon>
                    {{
                      cancelNewPrice
                      ? "Cancelar agendamento da alteração de valor"
                      : "Adicionar novo valor"
                    }}</v-btn>
                </v-col>
                <v-col v-if="formData.effective_date" cols="12" sm="12" md="12" class="pl-5 mt-0">
                  <v-switch v-model="only_new_subscription">
                    <template v-slot:label>
                      Novo valor somente para novas subscrições
                      <v-tooltip right max-width="210">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small v-bind="attrs" v-on="on" color="info" class="ml-2">help</v-icon>
                        </template>
                        <span>Ativando essa opção o novo valor só afetará as novas
                          subscrições. As subscrições já existentes continuarão
                          com o valor antigo.</span>
                      </v-tooltip>
                    </template>
                  </v-switch>
                </v-col>
                <v-col v-if="formData.effective_date" cols="12" sm="6" md="6" class="pl-5 mt-0">
                  <v-text-field-money v-model="formData.new_price" v-bind:label="'Novo valor'" v-bind:properties="{
                    rules: [],
                    prefix:
                      formData.new_price && formData.new_price.length
                        ? 'R$'
                        : '',
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    clearable: true,
                    placeholder: ' ',
                  }" v-bind:options="{
  locale: 'pt-BR',
  length: 11,
  precision: 2,
  empty: null,
}" />
                </v-col>
                <v-col v-if="formData.effective_date" cols="12" md="6" class="mt-0 pr-5">
                  <v-menu v-model="effective_date_menu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field clearable v-model="effective_date_formated" label="Data de início do novo valor"
                        readonly v-on="on" hint="O campo acima deve conter a data em que o novo valor entrará em vigor."
                        persistent-hint></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="formData.effective_date"
                      @input="effective_date_menu = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="toogleRegisterAddPacksDilog">
            Fechar
          </v-btn>
          <v-btn color="green" text @click="saveAddPacks"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const resetAddPacksForm = {
  id: null,
  urn_basic: 0,
  start_commercialization: "",
  end_commercialization: "",
  name: "",
  urn_prefix: "",
  price: "",
  id_service: "",
  new_price: "",
  effective_date: "",
  user_name: "",
  user_id: "",
};
import * as moment from "moment";
export default {
  methods: {
    setNewPrice() {
      if (this.cancelNewPrice) {
        let obj = {
          message: `Para cancelar o novo valor é necessário clicar em "salvar" caso contrário as alterações não serão validadas.<br/><b>Deseja continuar?</b>`,
          title: "Cancelar novo valor",
          callback: (_) => {
            this.formData.new_price = "";
            this.formData.effective_date = "";
            setTimeout(() => {
              this.cancelNewPrice = false;
              //this.saveAddPacks("cancelNewPrice");
            }, 10);
          },
          type: "warning",
          btConfirm: { name: "Sim", show: true },
          btCancel: { name: "Não", show: true },
        };
        this.$store.commit("confirmMessage/showConfirmMessage", obj);
        return;
      }
      this.formData.new_price = 0.0;
      // var hj = moment().add(1, "day").endOf("day");
      let tomorrow = moment(new Date()).add(1, "day").format("YYYY-MM-DD");
      // console.log(hj);
      this.formData.effective_date = tomorrow;
      setTimeout(() => {
        this.cancelNewPrice = true;
      }, 10);
      console.log("aaa");
    },
    saveAddPacks() {
      //console.log("servicsave");
      if (!this.$refs.addPacksForm.validate()) {
        return;
      }
      this.formData.user_name = this.userName;
      this.formData.user_id = this.userIdLogged;
      if (this.only_new_subscription) {
        this.formData.only_new_subscription = "1";
      } else {
        this.formData.only_new_subscription = "0";
      }

      let url = this.formData.id ? "/api/urn/update" : "/api/urn/create";
      var obj = {
        url: url,
        query: this.formData,
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        let opts = {
          message: `Pacote adicional ${this.formData.id ? "atualizado" : "criado"
            } com sucesso`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
        //this.updateAddPacks(response.data);
        this.getAddPacks();
        this.toogleRegisterAddPacksDilog();
      });
    },
    setEscClose() {
      console.log('close')
      let _this = this;
      document.onkeydown = function (evt) {
        evt = evt || window.event;
        var isEscape = false;
        if ("key" in evt) {
          isEscape = (evt.key === "Escape" || evt.key === "Esc");
        } else {
          isEscape = (evt.keyCode === 27);
        }
        if (isEscape) {
          _this.toogleRegisterAddPacksDilog()
          document.onkeydown = null;
        }
      };
    },
  },
  watch: {
    addPacksDialog(v) {
      if (!v) {
        document.onkeydown = null;
        this.$refs.addPacksForm.resetValidation();
        this.selectedCompany = null;
        this.formData = { ...resetAddPacksForm };
        this.cancelNewPrice = false;
        this.only_new_subscription = false;
      } else {
        this.setEscClose()
        if (this.editAddPacksData) {
          let index = this.companies.findIndex(
            (company) => company.id === this.editAddPacksData.company_id
          );
          this.selectedCompany = { ...this.companies[index] };
          //console.log('ddddd',this.editAddPacksData)
          this.formData = this.editAddPacksData;
          if (this.formData.only_new_subscription == "1") {
            this.only_new_subscription = true;
          } else {
            this.only_new_subscription = false;
          }
          if (this.formData.effective_date) {
            this.cancelNewPrice = true;
          }
        }
      }
    },
  },
  computed: {
    userIdLogged() {
      return localStorage.getItem("userId");
    },
    userName() {
      return localStorage.getItem("userName")
        ? localStorage.getItem("userName")
        : "Aguardando nome do usuário";
    },
    end_commercialization_formated: {
      get: function () {
        let date = this.formData.end_commercialization
          ? moment(this.formData.end_commercialization).format("DD/MM/YYYY")
          : "";
        return date == "Invalid date" ? null : date;
      },
      // setter
      set: function (newValue) {
        let date = moment(this.formData.end_commercialization).format(
          "DD/MM/YYYY"
        );
        console.log("aaaa", newValue);
        if (date == "Invalid date") {
          return null;
        }
        if (!newValue) {
          this.formData.end_commercialization = newValue;
        }
        return newValue;
      },
    },
    start_commercialization_formated: {
      get: function () {
        let date = this.formData.start_commercialization
          ? moment(this.formData.start_commercialization).format("DD/MM/YYYY")
          : "";
        return date == "Invalid date" ? null : date;
      },
      // setter
      set: function (newValue) {
        let date = moment(this.formData.start_commercialization).format(
          "DD/MM/YYYY"
        );
        if (date == "Invalid date") {
          return null;
        }
        if (!newValue) {
          this.formData.start_commercialization = newValue;
        }
        return newValue;
      },
    },
    effective_date_formated: {
      get: function () {
        let date = moment(this.formData.effective_date).format("DD/MM/YYYY");
        return date == "Invalid date" ? null : date;
      },
      // setter
      set: function (newValue) {
        let date = moment(this.formData.effective_date).format("DD/MM/YYYY");
        if (date == "Invalid date") {
          return null;
        }
      },
    },
  },
  mounted() { },
  props: {
    getAddPacks: Function,
    services: Array,
    updateAddPacks: Function,
    editAddPacksData: Object,
    toogleRegisterAddPacksDilog: Function,
    addPacksDialog: Boolean,
  },
  data() {
    return {
      only_new_subscription: false,
      cancelNewPrice: false,
      effective_date_menu: false,
      start_commercialization_menu: false,
      end_commercialization_menu: false,
      companies: [],
      selectedCompany: null,
      valid: true,
      formData: { ...resetAddPacksForm },
    };
  },
};
</script>

<style>
.labelTitle label {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.borderRadius {
  border-radius: 5px;
}
</style>