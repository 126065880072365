<template>
  <div>
    <v-data-table light :headers="headers" :items="promotions" :items-per-page="10"
      class="elevation-0 opacityTable mx-15">
      <template v-slot:[`item.status`]="{ item }">
        <div class="text-left">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-1" v-on="on" v-bind="attrs" :color="item.status == 'ativa' ? 'success' : 'error'">{{
                item.status == "ativa" ? "check_circle" : "block" }}</v-icon>
            </template>
            <span class="miniTtip">{{
              item.status == "ativa" ? "Promoção ativa" : "Desativada"
            }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.free_access`]="{ item }">
        <div class="text-center">{{ item.free_access ? "Sim" : "Não" }}</div>
      </template>
      <template v-slot:[`item.quantity_days`]="{ item }">
        {{ item.quantity_days != '-1' ? item.quantity_days + ' dias' : 'Indefinido' }}
      </template>
      <template v-slot:[`item.discount`]="{ item }">
        <div v-if="item.discount" class="">
          <div><small><b>Desconto</b></small></div>
          <div>
            {{ item.discount }}%
          </div>
        </div>
        <div v-if="item.value" class="">
          <div><small><b>Preço</b></small></div>
          <div>R$ {{ item.value | formatPrice }}</div>
        </div>
      </template>
      <template v-slot:[`item.start_date`]="{ item }">
        {{ item.start_date | formatDate }}
      </template>
      <template v-slot:[`item.end_date`]="{ item }">
        {{ item.end_date | formatDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-3" @click="editPromotions(item)" x-small
              color="primary" dark><v-icon center x-small> edit </v-icon></v-btn>
          </template>
          <span class="miniTtip">Editar Promoção</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-3" @click="confirmDeactivePromotions(item)" x-small
              :color="item.status == 'ativa' ? 'grey' : 'success'" dark><v-icon center x-small>
                {{ item.status == "ativa" ? "block" : "check_circle" }}
              </v-icon></v-btn>
          </template>
          <span class="miniTtip">{{
            item.status == "ativa" ? "Desativar Promoção" : "Ativar Promoção"
          }}</span>
        </v-tooltip>
        <v-tooltip v-if="item.can_delete" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="btMicro px-1" @click="confirmDelPromotions(item)" x-small color="red"
              dark><v-icon center x-small> mdi-delete-forever </v-icon></v-btn>
          </template>
          <span class="miniTtip">Excluir Promoção</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import MyFilters from "../../filters/genericFilters";
export default {
  methods: {},
  props: {
    promotions: Array,
    toogleRegisterPromotionsDilog: Function,
    editPromotions: Function,
    confirmDelPromotions: Function,
    confirmDeactivePromotions: Function,
  },
  data() {
    return {
      headers: [
        { text: "Status", value: "status", sortable: true },
        { text: "Nome/Descrição", value: "name", sortable: true },
        { text: "Data inicial", value: "start_date", sortable: false },
        { text: "Data final", value: "end_date", sortable: false },
        {
          text: "Preço ou desconto",
          value: "discount",
          sortable: false,
          align: "left",
        },
        {
          text: "Grátis",
          value: "free_access",
          sortable: false,
          align: "center",
        },
        { text: "Validade", value: "quantity_days", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>