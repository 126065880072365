<template>
    <div>
        <v-data-table class="elevation-0 opacityTable mx-15" :headers="headers" :items="checkpoints" :items-per-page="5">
            <template v-slot:item.price="{ item }">
                R$ {{ item.price | formatPrice }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-3" @click="editChkpoint(item)" x-small
                            color="primary" dark><v-icon center x-small> edit </v-icon></v-btn>
                    </template>
                    <span class="miniTtip">Editar Checkpoint</span>
                </v-tooltip>
                <!-- v-if="parseInt(item.can_delete)" -->
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" class="btMicro px-1" @click="confirmDelChkpoint(item)" x-small
                            color="red" dark><v-icon center x-small> mdi-delete-forever </v-icon></v-btn>
                    </template>
                    <span class="miniTtip">Excluir Checkpoint</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <CheckpointForm :getCheckpoints="getCheckpoints" :editChkpointData="editChkpointData"
            :chkpointDialog="chkpointDialog" :toogleChkpointAdm="toogleChkpointAdm" />
    </div>
</template>

<script>
import CheckpointForm from "./CheckpointForm.vue"
export default {
    methods: {
        editChkpoint(data) {
            this.editChkpointData = { ...data }
            this.toogleChkpointAdm()
        },
        confirmDelChkpoint(data) {
            let obj = {
                message: `Você deseja excluir o Checkpoint <b>${data.name}</b>?`,
                title: "Excluir Checkpoint",
                callback: (_) => {
                    this.delChkpoint(data);
                },
                type: "warning",
                btConfirm: { name: "Sim", show: true },
                btCancel: { name: "Não", show: true },
            };
            this.$store.commit("confirmMessage/showConfirmMessage", obj);
        },
        delChkpoint(data) {
            let obj = {
                url: "/api/checkpoint/licences/delete",
                query: { id: data.id },
                method: "post",
            };

            this.$store.dispatch("request/doRequest", obj).then((response) => {
                if (!response) {
                    return;
                }
                let opts = {
                    message: `Checkpoint removido com sucesso`,
                    type: "success",
                };
                this.$store.commit("showAlert", opts);
                this.getCheckpoints()
            });
        },
        toogleChkpointAdm() {
            this.chkpointDialog = !this.chkpointDialog
            // if (!this.chkpointDialog) {
            //     this.editChkpointData = null
            // }
        },
        getCheckpoints() {
            var obj = {
                url: "/api/checkpoint/licences/getAll",
                query: {},
                method: "get",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.checkpoints = [...response.data];
            });
        }
    },
    watch: {
        chkpointDialog(v) {
            if (!v) {
                console.log('ww')
                this.editChkpointData = null
            }
        }
    },
    data() {
        return {
            editChkpointData: null,
            chkpointDialog: false,
            checkpoints: [],
            headers: [
                { text: "Nome", value: "name", sortable: true },
                { text: "Preço", value: "price", sortable: false },
                { text: "Ações", value: "actions", sortable: false },
            ]
        }
    },
    mounted() {
        this.getCheckpoints();
    },
    components: {
        CheckpointForm,
    },
}
</script>

<style></style>