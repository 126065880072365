var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 opacityTable mx-15",attrs:{"light":"","headers":_vm.headers,"items":_vm.promotions,"items-per-page":10},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":item.status == 'ativa' ? 'success' : 'error'}},'v-icon',attrs,false),on),[_vm._v(_vm._s(item.status == "ativa" ? "check_circle" : "block"))])]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v(_vm._s(item.status == "ativa" ? "Promoção ativa" : "Desativada"))])])],1)]}},{key:"item.free_access",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(item.free_access ? "Sim" : "Não"))])]}},{key:"item.quantity_days",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity_days != '-1' ? item.quantity_days + ' dias' : 'Indefinido')+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [(item.discount)?_c('div',{},[_c('div',[_c('small',[_c('b',[_vm._v("Desconto")])])]),_c('div',[_vm._v(" "+_vm._s(item.discount)+"% ")])]):_vm._e(),(item.value)?_c('div',{},[_c('div',[_c('small',[_c('b',[_vm._v("Preço")])])]),_c('div',[_vm._v("R$ "+_vm._s(_vm._f("formatPrice")(item.value)))])]):_vm._e()]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.start_date))+" ")]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.end_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1 mr-3",attrs:{"x-small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.editPromotions(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" edit ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Editar Promoção")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1 mr-3",attrs:{"x-small":"","color":item.status == 'ativa' ? 'grey' : 'success',"dark":""},on:{"click":function($event){return _vm.confirmDeactivePromotions(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" "+_vm._s(item.status == "ativa" ? "block" : "check_circle")+" ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v(_vm._s(item.status == "ativa" ? "Desativar Promoção" : "Ativar Promoção"))])]),(item.can_delete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1",attrs:{"x-small":"","color":"red","dark":""},on:{"click":function($event){return _vm.confirmDelPromotions(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" mdi-delete-forever ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Excluir Promoção")])]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }