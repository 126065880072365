<template>
  <div class="pl-16 pr-2">
    <v-card :min-height="windowH" class="mx-auto mt-3 mb-9 bgTransparente" max-width="calc(100% - 10px)" elevation="0">
      <v-card-title class="ml-15 pt-8 pl-0 black--text">
        <v-tabs v-model="tab">
          <v-tab>Pacotes DGO</v-tab>
          <v-tab>Promoções DGO</v-tab>
          <v-tab>Pacotes Checkpoint</v-tab>
        </v-tabs>
      </v-card-title>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="toogleRegisterAddPacksDilog" v-bind="attrs" v-on="on" class="mt-13 mr-10" color="primary" fab
            dark :fixed="responsiveSize < 500" :absolute="responsiveSize >= 500" :bottom="responsiveSize < 500"
            :top="responsiveSize >= 500" right>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{
          tab > 0 ? "Adicionar promoção" : "Adicionar Pacote adicional"
        }}</span>
      </v-tooltip>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-data-table light :headers="headers" :items="AddPacks" :items-per-page="10"
            class="elevation-0 opacityTable mx-15">
            <template v-slot:[`item.status`]="{ item }">
              <div class="text-left">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-1" v-on="on" v-bind="attrs"
                      :color="item.status == 'ativa' ? 'success' : 'error'">{{
                        item.status == "ativa" ? "check_circle" : "block"
                      }}</v-icon>
                  </template>
                  <span class="miniTtip">{{
                    item.status == "ativa"
                      ? "Pacote ativo"
                      : "Pacote desativado"
                  }}</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:item.price="{ item }">
              R$ {{ item.price | formatPrice }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-3" @click="editAddPacks(item)" x-small
                    color="primary" dark><v-icon center x-small> edit </v-icon></v-btn>
                </template>
                <span class="miniTtip">Editar Pacote adicional</span>
              </v-tooltip>
              <v-tooltip top v-if="parseInt(item.can_delete)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="btMicro px-1" @click="confirmDelAddPacks(item)" x-small
                    color="red" dark><v-icon center x-small> mdi-delete-forever </v-icon></v-btn>
                </template>
                <span class="miniTtip">Excluir Pacote adicional</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <Promotions :promotions="promotions" :toogleRegisterPromotionsDilog="toogleRegisterPromotionsDilog"
            :editPromotions="editPromotions" :confirmDelPromotions="confirmDelPromotions"
            :confirmDeactivePromotions="confirmDeactivePromotions" />
        </v-tab-item>
        <v-tab-item>
          <CheckpointRegister ref="chkpointAdm" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <RegisterAddPacks :getAddPacks="getAddPacks" :services="services" :updateAddPacks="updateAddPacks"
      :editAddPacksData="editAddPacksData" :addPacksDialog="addPacksDialog"
      :toogleRegisterAddPacksDilog="toogleRegisterAddPacksDilog" />
    <RegisterPromotions :services="services" :AddPacks="AddPacks.map((pack) => {
      pack.urn_id = pack.id;
      return pack;
    })
      " :getPromotions="getPromotions" :updatePromotions="updatePromotions" :editPromotionsData="editPromotionsData"
      :toogleRegisterPromotionsDilog="toogleRegisterPromotionsDilog" :promotionsDialog="promotionsDialog" />
  </div>
</template>

<script>
import MyFilters from "../../filters/genericFilters";
import RegisterAddPacks from "./RegisterAddPacks.vue";
import RegisterPromotions from "../promotions/RegisterPromotions.vue";
import Promotions from "../promotions/Promotions.vue";
import CheckpointRegister from "./checkpoint/CheckpointList.vue"
export default {
  computed: {
    userIdLogged() {
      return localStorage.getItem("userId");
    },
    userName() {
      return localStorage.getItem("userName")
        ? localStorage.getItem("userName")
        : "Aguardando nome do usuário";
    },
    responsiveSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  beforeMount() {
    this.getHeightPage();
  },
  mounted() {
    this.getServices().then(_ => {
      this.getPacksAndPromotions()
    });
  },
  methods: {
    updatePromotions(data) { },
    updateAddPacks(data) {
      //console.log("update");
      let index = this.AddPacks.findIndex(
        (AddPacks) => AddPacks.id === data.id
      );
      if (index > -1) {
        this.AddPacks.splice(index, 1);
        this.AddPacks.splice(index, 0, data);
      } else {
        this.AddPacks.push(data);
      }
    },
    editAddPacks(data) {
      this.editAddPacksData = { ...data };
      this.addPacksDialog = true;
    },
    editPromotions(data) {
      //console.log('editprom',data)
      this.editPromotionsData = { ...data };
      this.promotionsDialog = true;
    },
    confirmDeactivePromotions(data) {
      let statustxt = data.status == "ativa" ? "desativar" : "ativar";
      let obj = {
        message: `Você deseja ${statustxt} a promoção <b>${data.name}</b>?`,
        title: `${statustxt[0].toUpperCase() + statustxt.substr(1)} Promoção`,
        callback: (_) => {
          this.deactivePromotion(data);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    deactivePromotion(data) {
      let obj = {
        url: "/api/rule/status",
        query: {
          id: data.id,
          id_service: data.id_service,
          status: `${data.status == "ativa" ? "inativa" : "ativa"}`,
        },
        method: "post",
      };
      obj.query.user_name = this.userName;
      obj.query.user_id = this.userIdLogged;
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        let opts = {
          message: `Promoção <b>${data.name}</b> atualizada com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
        this.getPromotions();
      });
    },
    confirmDelPromotions(data) {
      let obj = {
        message: `Você deseja excluir a promoção <b>${data.name}</b>?`,
        title: "Excluir Promoção",
        callback: (_) => {
          this.delPromotion(data);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    delPromotion(data) {
      let obj = {
        url: "/api/rule/delete",
        query: { id: data.id, id_service: data.id_service },
        method: "post",
      };
      obj.query.user_name = this.userName;
      obj.query.user_id = this.userIdLogged;
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        let index = this.promotions.findIndex((promo) => promo.id === data.id);
        this.promotions.splice(index, 1);
        let opts = {
          message: `Promoção <b>${data.name}</b> removida com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
    confirmDelAddPacks(data) {
      let obj = {
        message: `Você deseja excluir Pacote adicional <b>${data.name}</b>?`,
        title: "Excluir Pacote adicional",
        callback: (_) => {
          this.delAddPacks(data);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    delAddPacks(data) {
      let obj = {
        url: "/api/urn/delete",
        query: { id: data.id, id_service: data.id_service },
        method: "post",
      };
      obj.query.user_name = this.userName;
      obj.query.user_id = this.userIdLogged;
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        let index = this.AddPacks.findIndex(
          (provider) => provider.id === data.id
        );
        this.AddPacks.splice(index, 1);
        let opts = {
          message: `Pacote adicional <b>${data.name}</b> removido com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
    toogleRegisterPromotionsDilog() {
      this.editPromotionsData = null;
      this.promotionsDialog = !this.promotionsDialog;
    },
    toogleRegisterAddPacksDilog() {
      if (this.tab == 1) {
        this.toogleRegisterPromotionsDilog();
        return;
      }
      if (this.tab == 2) {
        console.log('ooo')
        this.$refs.chkpointAdm.toogleChkpointAdm()
        return
      }
      this.editAddPacksData = null;
      this.addPacksDialog = !this.addPacksDialog;
    },
    getHeightPage() {
      this.windowH = window.innerHeight - 125;

    },
    getPacksAndPromotions() {
      this.getAddPacks();
      this.getPromotions();
    },
    getPromotions() {
      // if (!this.id_service) {
      //   return;
      // }
      let index = this.services.findIndex(
        (service) => service.prefix == 'dtvgo'
      );
      let idService = this.services[index].id
      var obj = {
        url: "/api/rule/get-all",
        query: { id_service: idService },
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.promotions = [...response.data];
      });
    },
    async getAddPacks() {
      // if (!this.id_service) {
      //   return;
      // }
      let index = this.services.findIndex(
        (service) => service.prefix == 'dtvgo'
      );
      let idService = this.services[index].id
      var obj = {
        url: "/api/urn/get-all",
        query: { id_service: idService },
        method: "post",
      };
      //console.log(obj);
      await this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.AddPacks = [...response.data];
      });
    },
    async getServices() {
      var obj = {
        url: "/api/service/get-all",
        query: null,
        method: "get",
      };
      //console.log(obj);
      await this.$store.dispatch("request/doRequest", obj).then((response) => {
        this.services = [...response.data.filter((serv) => serv.prefix == 'dtvgo')];
      });
    },
  },

  data() {
    return {
      windowH: "580",
      tab: null,
      promotions: [],
      promotionsDialog: false,
      editPromotionsData: null,
      editAddPacksData: null,
      addPacksDialog: false,
      headers: [
        { text: "Status", value: "status", sortable: true },
        { text: "Nome", value: "name", sortable: true },
        { text: "URN", value: "urn_prefix", sortable: false },
        { text: "Preço", value: "price", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      AddPacks: [],
      services: [],
      id_service: "",
    };
  },
  components: {
    RegisterAddPacks,
    Promotions,
    RegisterPromotions,
    CheckpointRegister
  },
  created() {
    window.addEventListener("resize", this.getHeightPage);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeightPage);
  },
};
</script>

<style></style>