var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-16 pr-2"},[_c('v-card',{staticClass:"mx-auto mt-3 mb-9 bgTransparente",attrs:{"min-height":_vm.windowH,"max-width":"calc(100% - 10px)","elevation":"0"}},[_c('v-card-title',{staticClass:"ml-15 pt-8 pl-0 black--text"},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Pacotes DGO")]),_c('v-tab',[_vm._v("Promoções DGO")]),_c('v-tab',[_vm._v("Pacotes Checkpoint")])],1)],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-13 mr-10",attrs:{"color":"primary","fab":"","dark":"","fixed":_vm.responsiveSize < 500,"absolute":_vm.responsiveSize >= 500,"bottom":_vm.responsiveSize < 500,"top":_vm.responsiveSize >= 500,"right":""},on:{"click":_vm.toogleRegisterAddPacksDilog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.tab > 0 ? "Adicionar promoção" : "Adicionar Pacote adicional"))])]),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0 opacityTable mx-15",attrs:{"light":"","headers":_vm.headers,"items":_vm.AddPacks,"items-per-page":10},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":item.status == 'ativa' ? 'success' : 'error'}},'v-icon',attrs,false),on),[_vm._v(_vm._s(item.status == "ativa" ? "check_circle" : "block"))])]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v(_vm._s(item.status == "ativa" ? "Pacote ativo" : "Pacote desativado"))])])],1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm._f("formatPrice")(item.price))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1 mr-3",attrs:{"x-small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.editAddPacks(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" edit ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Editar Pacote adicional")])]),(parseInt(item.can_delete))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1",attrs:{"x-small":"","color":"red","dark":""},on:{"click":function($event){return _vm.confirmDelAddPacks(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" mdi-delete-forever ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Excluir Pacote adicional")])]):_vm._e()]}}],null,true)})],1),_c('v-tab-item',[_c('Promotions',{attrs:{"promotions":_vm.promotions,"toogleRegisterPromotionsDilog":_vm.toogleRegisterPromotionsDilog,"editPromotions":_vm.editPromotions,"confirmDelPromotions":_vm.confirmDelPromotions,"confirmDeactivePromotions":_vm.confirmDeactivePromotions}})],1),_c('v-tab-item',[_c('CheckpointRegister',{ref:"chkpointAdm"})],1)],1)],1),_c('RegisterAddPacks',{attrs:{"getAddPacks":_vm.getAddPacks,"services":_vm.services,"updateAddPacks":_vm.updateAddPacks,"editAddPacksData":_vm.editAddPacksData,"addPacksDialog":_vm.addPacksDialog,"toogleRegisterAddPacksDilog":_vm.toogleRegisterAddPacksDilog}}),_c('RegisterPromotions',{attrs:{"services":_vm.services,"AddPacks":_vm.AddPacks.map(function (pack) {
    pack.urn_id = pack.id;
    return pack;
  }),"getPromotions":_vm.getPromotions,"updatePromotions":_vm.updatePromotions,"editPromotionsData":_vm.editPromotionsData,"toogleRegisterPromotionsDilog":_vm.toogleRegisterPromotionsDilog,"promotionsDialog":_vm.promotionsDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }