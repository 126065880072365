<template>
  <v-row justify="center">
    <v-dialog light v-model="promotionsDialog" persistent max-width="750px">
      <v-card>
        <v-card-title class="headline px-2">
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="text-h5 pl-0">
              {{ formData.id ? "Editar Promoção" : "Nova Promoção" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-fab-transition> -->
            <v-btn @click="toogleRegisterPromotionsDilog" class="mt-2" color="red" fab icon dark x-small absolute top
              right>
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- </v-fab-transition> -->
          </v-app-bar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="promotionsForm" v-model="valid">
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field :disabled="formData.id && !formData.can_delete" v-model="formData.name"
                    label="Descrição da promoção" :rules="$store.state.formRules.required"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select :disabled="formData.id && !formData.can_delete" :rules="$store.state.formRules.required"
                    v-model="formData.id_service" :items="services" label="Escolha um aplicativo" item-text="name"
                    item-value="id"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select :disabled="formData.id && !formData.can_delete" :rules="$store.state.formRules.required"
                    v-model="formData.urn_id" :items="AddPacks" label="Escolha um pacote" item-text="name"
                    item-value="urn_id">
                  </v-select>
                </v-col>

                <v-col cols="12" md="6" class="pl-2 pr-3">
                  <v-menu :disabled="formData.id && !formData.can_delete" v-model="start_date_menu"
                    :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field :disabled="formData.id && !formData.can_delete" clearable
                        v-model="start_date_formated" label="Data inicial" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="formData.start_date"
                      @input="start_date_menu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" class="pl-2 pr-3">
                  <v-menu v-model="end_date_menu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field clearable v-model="end_date_formated" label="Data final" readonly
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="formData.end_date" @input="end_date_menu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="py-0" cols="12" sm="12" md="12">
                  <v-switch @change="changePercentValue" :disabled="formData.id && !formData.can_delete" class="pt-0" v-model="percentValue"
                    label="Valor da promoção em porcentagem"></v-switch>
                </v-col>
                <v-col v-if="!percentValue" cols="12" sm="12" md="6">
                  <v-text-field-money v-model="formData.value" v-bind:label="'Preço promocional'" v-bind:properties="{
                    rules: $store.state.formRules.required,
                    prefix:
                      formData.value && formData.value.length ? 'R$' : '',
                    readonly: false,
                    disabled: formData.id && !parseInt(formData.can_delete),
                    outlined: false,
                    clearable: true,
                    placeholder: ' ',
                  }" v-bind:options="{
  locale: 'pt-BR',
  length: 11,
  precision: 2,
  empty: null,
}" />
                  <!-- <v-text-field
                    v-model="formData.price"
                    label="Preço"
                    :rules="$store.state.formRules.required"
                  ></v-text-field> -->
                </v-col>
                <v-col v-if="percentValue" cols="12" sm="12" md="6">
                  <v-text-field :disabled="formData.id && !formData.can_delete" v-model="formData.discount" prefix="(%)"
                    type="number" label="Valor da promoção em porcentagem"
                    :rules="$store.state.formRules.required"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <!-- :disabled="formData.id && !formData.can_delete" -->
                  <v-text-field min="-1" v-model="formData.quantity_days"
                    label="Duração da promoção em dias" type="number" :rules="$store.state.formRules.required"
                    hint="Período em que a subscrição terá o valor promocional. Caso não haja uma data final o valor digitado deve ser -1"
                    persistent-hint></v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="6" md="4">
                  <v-text-field-money
                    v-model="formData.price"
                    v-bind:label="'Preço / Valor'"
                    v-bind:properties="{
                      rules: $store.state.formRules.required,
                      prefix:
                        formData.price && formData.price.length ? 'R$' : '',
                      readonly: false,
                      disabled: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                    }"
                    v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 2,
                      empty: null,
                    }"
                  />
                </v-col> -->
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="toogleRegisterPromotionsDilog">
            Fechar
          </v-btn>
          <v-btn color="green" text @click="savePromotions"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const resetPromotionsForm = {
  id: null,
  id_service: "",
  urn_id: "",
  name: "",
  start_date: "",
  end_date: "",
  discount: "",
  value: "",
  free_access: false,
  quantity_days: "",
  user_name: "",
  user_id: "",
};
import * as moment from "moment";
export default {
  methods: {
    changePercentValue() {
      this.formData.value = ""
      this.formData.discount = ""
    },
    savePromotions() {
      //console.log("servicsave");
      if (!this.$refs.promotionsForm.validate()) {
        return;
      }
      this.formData.user_name = this.userName;
      this.formData.user_id = this.userIdLogged;
      if (this.formData.discount >= 100) {
        this.formData.free_access = true;
      } else {
        this.formData.free_access = false;
      }
      let url = this.formData.id ? "/api/rule/update" : "/api/rule/create";
      var obj = {
        url: url,
        query: this.formData,
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        let opts = {
          message: `Promoção ${this.formData.id ? "atualizada" : "criada"
            } com sucesso`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
        //this.updatePromotions(response.data);
        this.getPromotions();
        this.toogleRegisterPromotionsDilog();
      });
    },
  },
  watch: {
    promotionsDialog(v) {
      if (!v) {
        this.$refs.promotionsForm.resetValidation();
        this.selectedCompany = null;
        this.formData = { ...resetPromotionsForm };
        this.percentValue = true
      } else {
        if (this.editPromotionsData) {
          let index = this.companies.findIndex(
            (company) => company.id === this.editPromotionsData.company_id
          );
          this.selectedCompany = { ...this.companies[index] };
          this.formData = this.editPromotionsData;
          this.percentValue = this.formData.value ? false : true
        }
      }
    },
  },
  mounted() { },
  props: {
    AddPacks: Array,
    getPromotions: Function,
    services: Array,
    updatePromotions: Function,
    editPromotionsData: Object,
    toogleRegisterPromotionsDilog: Function,
    promotionsDialog: Boolean,
  },
  data() {
    return {
      percentValue: true,
      companies: [],
      end_date_menu: false,
      start_date_menu: false,
      selectedCompany: null,
      valid: true,
      formData: { ...resetPromotionsForm },
    };
  },
  computed: {
    userIdLogged() {
      return localStorage.getItem("userId");
    },
    userName() {
      return localStorage.getItem("userName")
        ? localStorage.getItem("userName")
        : "Aguardando nome do usuário";
    },
    start_date_formated: {
      get: function () {
        let date = moment(this.formData.start_date).format("DD/MM/YYYY");
        return date == "Invalid date" ? null : date;
      },
      // setter
      set: function (newValue) {
        let date = moment(this.formData.start_date).format("DD/MM/YYYY");
        if (date == "Invalid date") {
          return null;
        }
      },
    },
    end_date_formated: {
      get: function () {
        let date = moment(this.formData.end_date).format("DD/MM/YYYY");
        return date == "Invalid date" ? null : date;
      },
      // setter
      set: function (newValue) {
        let date = moment(this.formData.end_date).format("DD/MM/YYYY");
        if (date == "Invalid date") {
          return null;
        }
      },
    },
  },
};
</script>

<style>
.labelTitle label {
  font-size: 18px !important;
  font-weight: 500 !important;
}
</style>